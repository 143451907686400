import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class BannerService {
    private _message: BehaviorSubject<BannerMessage>;
    private _permanentMessage: BehaviorSubject<BannerMessage>;

    constructor() {
        this._message = new BehaviorSubject(new BannerMessage());
        this._permanentMessage = new BehaviorSubject(new BannerMessage());
    }

    get message(): Observable<BannerMessage> {
        return this._message.asObservable();
    }

    get permanentMessage(): Observable<BannerMessage> {
        return this._permanentMessage.asObservable();
    }

    showMessage(text: string, isError: boolean = false, timeoutExpire: number = 3000) {
        this._message.next(new BannerMessage(text, isError, timeoutExpire));
    }

    showPermanentMessage(text: string, isError: boolean = false) {
        this._permanentMessage.next(new BannerMessage(text, isError));
    }

    reset() {
        this.showMessage('');
        this.showPermanentMessage('');
    }
}

export class BannerMessage {
    Text: string;
	IsError: boolean;
	TimeoutExpire: number;

    constructor(text: string = '', isError: boolean = false, timeoutExpire: number = 3000) {
        this.Text = text;
		this.IsError = isError;
		this.TimeoutExpire = timeoutExpire;
    }
}
